<template>
  <div>
    <div class="user-summary grid-container profile">
      <div class="left-side">
        <div class="flex flex-col items-center justify-center user">
          <span class="relative inline-block mb-4">
            <img
              class="inline-block object-cover w-32 h-32 rounded-full"
              :src="userPhotoUrl"
              :alt="'Avatar of ' + user.name"
            >
          </span>

          <form @submit.prevent="updateAvatar">
            <input
              id="avatar-upload"
              ref="photo"
              type="file"
              name="photo"
              accept=".png,.jpg,.jpeg"
              value="Upload Avatar"
              style="display: none"
              @change="updateAvatar"
            >
            <label
              v-show="!avatarForm.busy"
              for="avatar-upload"
              class="normal-case btn"
            ><fa-icon
              icon="upload"
              class="mr-2"
            /> Update Avatar</label>
            <label
              v-show="avatarForm.busy"
              for="avatar-upload"
              class="btn"
            ><fa-icon
              icon="spinner"
              spin
            /></label>
          </form>

          <div
            v-show="avatarForm.errors.has('form')"
            class="px-2 mb-4 text-sm italic text-red-500"
          >
            {{ avatarForm.errors.get('form') }}
          </div>
        </div>
      </div>

      <div class="mt-4 right-side md:mt-0">
        <form
          class="w-full max-w-sm"
          @submit.prevent="updateProfile"
        >
          <div class="mb-4 md:flex md:items-center">
            <div class="md:w-1/3">
              <label
                class="block mr-4 text-sm font-medium leading-5 text-gray-700 md:text-right"
                for="inline-first-name"
              >
                First Name
              </label>
            </div>
            <div class="relative mt-1 rounded-md shadow-sm md:w-2/3">
              <input
                id="inline-first-name"
                v-model="form.first_name"
                class="block w-full border border-gray-300 rounded-md focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50 sm:text-sm sm:leading-5"
                type="text"
              >
            </div>
          </div>
          <div
            v-show="form.errors.has('first_name')"
            class="px-2 mb-4 text-sm italic text-red-500"
          >
            {{ form.errors.get('first_name') }}
          </div>
          <div class="mb-4 md:flex md:items-center">
            <div class="md:w-1/3">
              <label
                class="block mr-4 text-sm font-medium leading-5 text-gray-700 md:text-right"
                for="inline-last-name"
              >
                Last Name
              </label>
            </div>
            <div class="relative mt-1 rounded-md shadow-sm md:w-2/3">
              <input
                id="inline-last-name"
                v-model="form.last_name"
                class="block w-full border border-gray-300 rounded-md focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50 sm:text-sm sm:leading-5"
                type="text"
              >
            </div>
          </div>
          <div
            v-show="form.errors.has('last_name')"
            class="px-2 mb-4 text-sm italic text-red-500"
          >
            {{ form.errors.get('last_name') }}
          </div>
          <div class="mb-4 md:flex md:items-center">
            <div class="md:w-1/3">
              <label
                class="block mr-4 text-sm font-medium leading-5 text-gray-700 md:text-right"
                for="inline-email"
              >
                Email
              </label>
            </div>
            <div class="relative mt-1 rounded-md shadow-sm md:w-2/3">
              <input
                id="inline-email"
                v-model="form.email"
                class="block w-full border border-gray-300 rounded-md focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50 sm:text-sm sm:leading-5"
                type="email"
              >
            </div>
          </div>
          <div
            v-show="form.errors.has('email')"
            class="px-2 mb-4 text-sm italic text-red-500"
          >
            {{ form.errors.get('email') }}
          </div>

          <div class="md:flex md:items-center">
            <div class="md:w-1/3" />
            <div class="md:w-2/3">
              <button
                v-show="form.busy"
                class="btn primary loading"
              >
                <fa-icon
                  icon="spinner"
                  spin
                />
              </button>
              <button
                v-show="!form.busy"
                type="submit"
                class="btn primary"
                :disabled="form.busy"
                @click.prevent="updateProfile"
              >
                Save
              </button>
            </div>
          </div>

          <div
            v-if="needsEmailValidation"
            class="mt-2 mb-4 text-sm italic text-red-500"
          >
            <template v-if="resendForm.busy">
              Resending..
            </template>
            <template v-else-if="showResendOption">
              Email address needs validation. Check your inbox <a
                href="#"
                class="underline cursor-pointer hover:text-black"
                @click.prevent="resendConfirmation"
              >Resend?</a>
            </template>
            <template v-else>
              Confirmation email resent. Check your inbox
            </template>
          </div>

          <div
            v-if="form.errors.has('form')"
            class="mt-2 mb-4 text-sm italic text-red-500"
          >
            {{ form.errors.get('form') }}
          </div>
        </form>
      </div>
    </div>

    <div class="p-2 mt-2 border-t border-gray-300 rounded-sm">
      <div class="py-2 mx-2 mt-0">
        <div class="flex items-center justify-center">
          <label class="flex mr-3 text-sm text-gray-600 align-middle">Dark Mode Preference</label>
          <select
            v-model="appDarkMode"
            class="flex px-4 py-2 text-xs text-gray-700 align-middle bg-white border border-gray-300 rounded appearance-none form-select focus:outline-none focus:bg-white focus:border-gray-500"
          >
            <option
              v-for="option in darkModeOptions"
              :value="option.value"
            >
              {{ option.label }}
            </option>
          </select>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PrevizDirectUpload from '@/libs/direct-upload.js'
import ImageResize from 'image-resize'

const DirectUpload = new PrevizDirectUpload()

export default {
  name: 'ProfileOverview',
  data () {
    return {
      avatarForm: new SparkForm({}),

      resendForm: new SparkForm({}),

      form: new SparkForm({
        first_name: '',
        last_name: '',
        email: ''
      }),
      showResendOption: true

    }
  },

  computed: {

    appDarkMode: {
      get () {
        return this.$store.getters['preferences/appDarkMode']
      },
      set (val) {
        this.$store.dispatch('preferences/setAppDarkMode', { choice: val })
      }
    },

    darkModeOptions () {
      return [
        { label: 'Light Mode', value: 'light' },
        { label: 'Dark Mode', value: 'dark' },
        { label: 'Use System Preference', value: 'system' }
      ]
    },

    userPhotoUrl () {
      if (this.user) {
        return this.user.photo_url
      }
      return ''
    },

    user () {
      return this.$store.getters.user
    },

    needsEmailValidation () {
      return this.$store.getters.doesUserNeedEmailValidation
    }
  },

  watch: {
    user: {
      handler () {
        this.form.first_name = this.user.first_name
        this.form.last_name = this.user.last_name
        this.form.email = this.user.email
      },
      immediate: true
    }
  },

  mounted: function () {
    this.form.first_name = this.user.first_name
    this.form.last_name = this.user.last_name
    this.form.email = this.user.email
  },

  methods: {
    resendConfirmation () {
      this.resendForm.startProcessing()
      this.$store
        .dispatch('confirmUserEmail', {
          email: this.user.email
        })
        .then(result => {
          this.resendForm.finishProcessing()
          this.showResendOption = false
          alert.success('Email resent')
        })
        .catch(error => {
          console.error(error)
          this.resendForm.stopProcessing()
          alert.error('Resend failed')
        })
    },

    updateAvatar (e) {
      e.preventDefault()
      this.avatarForm.startProcessing()

      var imageResize = new ImageResize({
        format: 'png',
        width: 200,
        outputType: 'blob'
      })

      const name = 'avatar.png'
      imageResize.play(this.$refs.photo)
        .then(blob => {
          const file = new File([blob], name)
          this.handleFileUpload(file)
        })
    },

    handleFileUpload (file) {
      DirectUpload.store(file, {
        progress: progress => {
          this.uploadProgress = Math.round(progress * 100)
        }
      })
        .then(response => {
          this.$store.dispatch('updatePhoto', {
            uuid: response.uuid,
            key: response.key,
            name: file.name,
            content_type: file.type
          })
            .then(() => {
              this.$store.dispatch('fetchUser')
                .then(() => {
                  this.avatarForm.finishProcessing()
                })
            })
            .catch(response => {
              this.avatarForm.setErrors(response.body)
            })
        })
        .catch(err => {
          console.warn('Failed uploading profile image', err)
          this.avatarForm.stopProcessing()
          this.avatarForm.setErrors({ 'form': ['Upload failed'] })
        })
    },

    updateProfile (e) {
      e.preventDefault()
      this.form.startProcessing()
      this.$store.dispatch(
        'updateContact',
        {
          first_name: this.form.first_name,
          last_name: this.form.last_name,
          email: this.form.email
        }
      )
        .then(() => {
          this.$store.dispatch('fetchUser')
            .then(() => {
              alert.success('Updated profile')
            })

          this.form.finishProcessing()
          alert.success('Profile updated')
        })
        .catch((response) => {
          this.form.setErrors(response.body)
        })
    }
  }
}
</script>
